<template>
    <app-page>
        <template #page-header>
            <page-header name="HTTPS Redirects" :loading="loading" v-model="search">
                <b-btn @click="getRedirects" class="mr-2" variant="light" :disabled="saving"><i class="fas fa-sync fa-fw"></i></b-btn>
                <b-btn @click="createRedirect" variant="primary" :disabled="saving">Create redirect <i class="fas fa-plus ml-1"></i></b-btn>

                <template #aside v-if="$store.state.cluster === 'demo'">
                    <b-alert show variant="info" class="m-0 py-2 f-500 w-auto d-inline mr-auto">DEMO redirects are the same as LIVE. It's on the same cluster no need for a demo pod.</b-alert>
                </template>
            </page-header>
        </template>
        <b-overlay :show="loading" rounded="sm">
            <b-table responsive="md" stacked="sm" hover borderless :fields="fields" :items="filteredRedirects">
                <template #cell(domain)="data">
                    <b-input v-if="selectedRow[data.index]" v-model="redirects[data.index].domain"></b-input>
                    <a :href="'https://' + data.value" target="_blank" v-else>{{ data.value }}</a>
                </template>

                <template #cell(redirect_url)="data">
                    <b-input v-if="selectedRow[data.index]" v-model="redirects[data.index].redirect_url"></b-input>
                    <span v-else>{{ data.value }}</span>
                </template>

                <template #cell(kubernetes)="data">
                    <b-badge v-if="data.value" variant="success">Deployed</b-badge>
                    <b-badge variant="warning" v-else-if="$moment().isBefore($moment(redirects[data.index].scheduled_ingress_apply))">Ready at {{$moment(redirects[data.index].scheduled_ingress_apply).format('HH:mm')}}</b-badge>
                    <b-badge v-else variant="danger" title="Resave after 10 minutes if still not deployed" v-b-tooltip>Not deployed</b-badge>
                </template>

                <template #cell(dns)="data">
                    <div v-if="selectedRow[data.index]">
                        <b-checkbox class="mr-1" switch v-model="redirects[data.index].dns_owned" v-b-tooltip.hover title="Uncheck this if domain is not on GoDaddy">
                            Domain on GoDaddy
                        </b-checkbox>
                    </div>
                    <template v-else>
                        <b-btn v-if="!dnsChecks[data.index]" @click="checkDNS(data)" size="sm" :disabled="checkingDNS[data.index]">
                            Check DNS
                            <i v-if="data.item.dns_owned" class="text-success fas fa-check" title="DNS owned on GoDaddy" v-b-tooltip></i>
                            <i v-else class="text-warning fas fa-warning" title="DNS not owned on GoDaddy" v-b-tooltip></i>
                        </b-btn>
                        <b-spinner v-if="checkingDNS[data.index]" variant="primary" small type="grow" label="Spinning"></b-spinner>
                        <span v-if="dnsChecks[data.index]">DNS Status: {{ dnsChecks[data.index] }}</span>
                    </template>
                </template>

                <template #cell(actions)="data">
                <span v-if="selectedRow[data.index]">
                    <b-btn @click="save(data)" variant="success" size="sm" :disabled="saving || !redirects[data.index].domain || !redirects[data.index].redirect_url">
                        Save
                        <b-spinner v-if="saving" small></b-spinner>
                    </b-btn>
                    <b-btn class="ml-2" size="sm" variant="light" @click="cancel(data)" :disabled="saving">
                        Cancel
                    </b-btn>
                </span>
                    <span v-else>
                    <b-btn @click="handleEditRow(data)" :disabled="saving" size="sm">Edit</b-btn>
                        <e-button-confirm @confirmed="deleteRedirect(data)" variant="danger" size="sm" :disabled="saving" class="ml-2"><i class="fas fa-close"></i></e-button-confirm>
                </span>

                </template>
            </b-table>
        </b-overlay>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";

export default {
    name: `https-redirects`,
    components: {EButtonConfirm},
    data() {
        return {
            loading: true,
            redirects: [],
            fields: [
                `domain`,
                `redirect_url`,
                `kubernetes`,
                `dns`,
                `actions`
            ],
            selectedRow: {},
            dnsChecks: {},
            checkingDNS: {},
            creationMode: false,
            saving: false,
            search: ``
        }
    },

    computed: {
        filteredRedirects() {
            if (!this.search) {
                return this.redirects;
            }

            return this.redirects.filter(r => r.domain.toLowerCase().includes(this.search.toLowerCase()) || r.redirect_url.toLowerCase().includes(this.search.toLowerCase()))
        }
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );

        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        init() {
            this.getRedirects();
            this.dnsChecks = {};
            this.checkingDNS = {};
        },
        getRedirects() {
            this.loading = true;
            Network.get(`/api/https-redirects/${this.$store.state.cluster}`)
                .then(resp => {
                    this.redirects = resp.data;
                    this.loading = false;
                });
        },
        handleEditRow(data) {
            this.selectedRow = {
                [data.index]: !this.selectedRow[data.index]
            }
        },
        checkDNS(data) {
            this.$set(this.checkingDNS, data.index, true);
            Network.get(`/api/https-redirects/check-dns/${this.$store.state.cluster}/${data.item.domain}`)
                .then(resp => {
                    this.$set(this.dnsChecks, data.index, resp.data.status);
                    this.$set(this.checkingDNS, data.index, false);
                    this.$forceUpdate();
                });
        },
        createRedirect() {
            if (this.creationMode) {
                return;
            }

            this.creationMode = true;

            this.redirects.push({
                domain: ``,
                redirect_url: ``,
                dns_owned: true
            });

            this.selectedRow = {
                [this.redirects.length - 1]: true
            }
        },
        save(data) {
            this.saving = true;
            this.creationMode = false;

            Network.post(`/api/https-redirects/set`, {
                cluster: this.$store.state.cluster,
                domain: data.item.domain,
                redirect_url: data.item.redirect_url,
                dns_owned: data.item.dns_owned
            }).finally(() => {
                this.saving = false;
                this.handleEditRow(data);
                this.getRedirects();
            });
        },
        cancel(data) {
            if (this.creationMode) {
                this.redirects.pop();
            }

            this.creationMode = false;

            this.handleEditRow(data);
        },
        deleteRedirect(data) {
            this.saving = true;
            Network.delete(`/api/https-redirects/delete/${this.$store.state.cluster}/${data.item.domain}`)
                .finally(() => {
                    this.saving = false;
                    this.getRedirects();
                });
        }
    }
};
</script>

